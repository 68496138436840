import React, { useState } from 'react'
import { Button } from 'antd'

import { useDispatch, useSelector } from 'react-redux'
import { authslice } from '../store/auth/authSlice'
import Layout from '../components/layout/Layout'

function Home() {
  const { logout } = authslice.actions
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)

  return (
    <Layout>
      <div>
        <h1>Welcome {auth.user.email}</h1>
        <p>User type: {auth.user.type}</p>
        <p>User verified: {JSON.stringify(auth.user.emailVerified)}</p>
        <Button
          danger
          type="primary"
          onClick={() => {
            dispatch(logout())
          }}
        >
          Logout
        </Button>
      </div>
    </Layout>
  )
}

export default Home
