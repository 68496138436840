import { createSlice } from '@reduxjs/toolkit'

// if (typeof window !== 'undefined') {
//   console.log('You are on the browser')
// } else {
//   console.log('You are on the server')
// }

// create a slice
export const authslice = createSlice({
  name: 'auth',
  initialState: {
    token:
      typeof window !== 'undefined'
        ? window.localStorage.getItem('token') || null
        : null,
    user:
      typeof window !== 'undefined'
        ? JSON.parse(window.localStorage.getItem('user')) || null
        : null,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload.user
      state.token = action.payload.token

      // Persist the token and user separately in localStorage
      typeof window !== 'undefined' &&
        window.localStorage.setItem('token', action.payload.token)
      typeof window !== 'undefined' &&
        window.localStorage.setItem('user', JSON.stringify(action.payload.user))
    },
    logout: (state) => {
      // Clear user and token from state
      state.user = null
      state.token = null

      // Clear user and token from localStorage
      if (typeof window !== 'undefined') {
        window.localStorage.removeItem('token')
        window.localStorage.removeItem('user')
      }
    },
  },
})
