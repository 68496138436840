import React, { useState, useEffect } from 'react'
import { Table, Spin, Popconfirm, Button } from 'antd'

import Layout from '../components/layout/Layout'
import axiosInstance from '../utils/axiosInstance'

function ActivitySuggestions() {
  const [loading, setLoading] = useState(false)
  const [submissions, setSubmissions] = useState([])

  const getSubmissions = async () => {
    try {
      setLoading(true)

      const response = await axiosInstance.get(`/api/v1/focus/getActivitySuggestions`)

      setSubmissions(response.data)
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  useEffect(() => {
    getSubmissions()
  }, [])

  const onDelete = async (id) => {
    const response = await axiosInstance.delete(`/api/v1/focus/deleteActivitySuggestion?id=${id}`)
    alert('Success')
    window.location.reload()
  }

  const completeSuggestion = async (id) => {
    const response = await axiosInstance.post(`/api/v1/focus/editActivitySuggestion?id=${id}`, {
      completed: true,
    })
    alert('Success')
    window.location.reload()
  }

  const columns = [
    {
      title: 'Sub Title',
      dataIndex: 'subTitle',
      key: 'subTitle',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      render: (_, record) => <div>{record.subTitle}</div>,
    },
    {
      title: 'Points',
      dataIndex: 'points',
      key: 'points',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      render: (_, record) => <div>{record.points}</div>,
    },
    {
      title: 'Quick Description',
      dataIndex: 'quickDescription',
      key: 'quickDescription',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      render: (_, record) => <div>{record.quickDescription}</div>,
    },
    {
      title: 'Added by',
      dataIndex: 'user',
      key: 'user',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
      render: (_, record) => (
        <div>
          <a href={`/member/${record.user?._id}`} target="_blank">
            {record.user?.firstName}
          </a>
        </div>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => new Date(text).toLocaleString(),
      responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      sorter: (a, b) => a.disabled - b.disabled,
      render: (_, record) => (
        <>
          <Popconfirm
            title="Are you sure to delete this?"
            description="Its can't be undone."
            onConfirm={() => onDelete(`${record._id}`)}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <Button type="ghost" style={{ color: 'red', cursor: 'pointer' }}>
              Delete
            </Button>
          </Popconfirm>
          {!record.completed && (
            <Button type="ghost" style={{ color: '#1677ff', cursor: 'pointer' }} onClick={() => completeSuggestion(record._id)}>
              Complete
            </Button>
          )}
        </>
      ),
    },
  ]

  return (
    <Layout>
      <div style={{ padding: '20px' }}>
        <h2>Activity Suggestions</h2>
        {loading ? (
          <Spin />
        ) : (
          <>
            <p>Count: {submissions.length}</p>
            <Table
              columns={columns}
              dataSource={submissions}
              scroll={{ x: 300 }}
              pagination={false}
              rowClassName={(record, index) => (record.completed ? 'table-row-complete' : 'table-row-incomplete')}
            />
          </>
        )}
      </div>
    </Layout>
  )
}

export default ActivitySuggestions
