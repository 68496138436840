import React, { useState, useRef, useEffect } from 'react'
import { Table, Avatar, Space, Drawer, Button, Select, Spin } from 'antd'
import { useNavigate } from 'react-router-dom'
import { ClearOutlined } from '@ant-design/icons'

import Layout from '../components/layout/Layout'
import axiosInstance from '../utils/axiosInstance'
import classes from './styles/Members.module.css'

function Members() {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState([])
  const [filteredUsers, setFilteredUsers] = useState([])
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false)
  const [filters, setFilters] = useState({})

  useEffect(() => {
    applyFilters()
  }, [filters, users])

  // Result pattern:
  // result = main
  // result = filter1 from result
  // result = filter2 from the previous result above
  // now result will show combination of two result based on order
  const applyFilters = () => {
    let result = users
    for (const key in filters) {
      if (filters[key] !== 'all') {
        result = result.filter((user) => user[key] === filters[key])
      }
    }
    setFilteredUsers(result)
  }

  // for setting all the filters at the global state
  const masterFilter = (key, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [key]: value }))
  }

  const showDrawer = () => {
    setFilterDrawerOpen(true)
  }
  const onClose = () => {
    setFilterDrawerOpen(false)
  }

  const columns = [
    // {
    //   title: '#',
    //   key: 'no.',
    //   render: (text, record, rowIndex) => rowIndex + 1,
    // },
    {
      title: 'Profile Picture',
      dataIndex: 'profilePicture',
      key: 'profilePicture',
      render: (profilePicture) => (
        <Avatar
          src={(profilePicture && profilePicture[0].bucketResponse.mediaLink) || `https://storage.googleapis.com/growdie/contents/manual-uploads/placeholder-people-compress-1.png`}
        />
      ),
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Joined at',
      dataIndex: 'joinedAt',
      key: 'joinedAt',
      render: (joinedAt) => <div>{joinedAt?.length > 0 ? <p>{joinedAt[0].name}</p> : 'Community'}</div>,
    },
    {
      title: 'Versions',
      key: 'versions',
      render: (record) => (
        <div>
          {record.deviceToken?.map((el, idx) => {
            return <span>{el.moreInfo?.installedAppVersion} ￮</span>
          })}
        </div>
      ),
    },
    // {
    //   title: 'Email Verified',
    //   dataIndex: 'emailVerified',
    //   key: 'emailVerified',
    //   render: (emailVerified) => (emailVerified ? 'Yes' : 'No'),
    // },
    {
      title: 'Signup Type',
      dataIndex: 'signupType',
      key: 'signupType',
    },
    {
      title: 'User Type',
      dataIndex: 'type',
      key: 'type',
    },
    // {
    //   title: 'Club Access',
    //   dataIndex: 'clubAccess',
    //   key: 'clubAccess',
    //   render: (clubAccess) => (clubAccess ? 'Yes' : 'No'),
    // },
    // {
    //   title: 'Digital Access',
    //   dataIndex: 'digitalAccess',
    //   key: 'digitalAccess',
    //   render: (digitalAccess) => (digitalAccess ? 'Yes' : 'No'),
    // },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => new Date(createdAt).toLocaleString(),
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',

      render: (_, record) => (
        <Space size="middle">
          <span style={{ color: '#1677ff', cursor: 'pointer' }} onClick={() => navigate(`/member/${record._id}`)}>
            Edit
          </span>
        </Space>
      ),
    },
  ]

  const getAllUsers = async (values) => {
    console.log('Success:', values)

    try {
      setLoading(true)

      const response = await axiosInstance.get('/api/v1/admin/getAllUsers')
      console.log(response.data)
      setUsers(response.data.users)
      setFilteredUsers(response.data.users)

      setLoading(false)
    } catch (error) {
      console.error('Request Error:', error)
      // You can handle the error here
      setLoading(false)
    }
  }

  // ! Old filter method ( but it can only filter only one filter at a time )
  const signupTypeChange = (value) => {
    // under 1000 users do frontend filtering
    console.log(value)
    const res = users.filter((el, idx) => {
      if (el.signupType == value) {
        return el
      } else {
        return false
      }
    })
    setFilteredUsers(res)
    if (value == 'all') {
      setFilteredUsers(users)
    }
  }

  useEffect(() => {
    getAllUsers()
  }, [])

  return (
    <Layout>
      <h1>Members ({filteredUsers.length})</h1>
      {loading && <Spin size="large" />}
      <br />
      <Button onClick={showDrawer}>Add filter</Button>

      {Object.keys(filters).length != 0 && (
        <Button onClick={() => window.location.reload()} icon={<ClearOutlined />} type="text" danger>
          Clear filter
        </Button>
      )}

      <Drawer title="Filters" onClose={onClose} open={filterDrawerOpen}>
        <div className={classes.child}>
          <p>Signup Type</p>
          <Select
            className={classes.selectTag}
            onChange={(v) => masterFilter('signupType', v)}
            options={[
              {
                value: 'all',
                label: 'All type',
              },
              {
                value: 'digital',
                label: 'Digital Community',
              },
              {
                value: 'growdieCode',
                label: 'Growdie Code',
              },
            ]}
          />
        </div>
        <div className={classes.child}>
          <p>User Type</p>
          <Select
            className={classes.selectTag}
            onChange={(v) => masterFilter('type', v)}
            options={[
              {
                value: 'all',
                label: 'All type of users',
              },
              {
                value: 'regular-user',
                label: 'Regular User',
              },
              {
                value: 'super-admin',
                label: 'Super Admin',
              },
              {
                value: 'regular-admin',
                label: 'Regular Admin',
              },
            ]}
          />
        </div>
      </Drawer>
      <Table
        scroll={{
          x: 1300,
        }}
        dataSource={filteredUsers}
        columns={columns}
        rowKey="_id"
        pagination={false}
      />
    </Layout>
  )
}

export default Members

const styles = {
  filterContainer: {
    background: '#f2f2f2',
  },
}
