import React, { useState, useRef, useEffect } from 'react'
import { Table, Spin, Avatar, Button, Card } from 'antd'
import { useNavigate, useLocation } from 'react-router'

import Layout from '../components/layout/Layout'
import axiosInstance from '../utils/axiosInstance'

function Metrics() {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [dataTwo, setDataTwo] = useState(null)

  const getData = async () => {
    try {
      setLoading(true)

      const response = await axiosInstance.get(`/api/v1/metrics/checkGoogleBucketSize`)

      setData(response.data)
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  // for total number of completed activities and their points
  const getDataTwo = async () => {
    try {
      setLoading(true)

      const response = await axiosInstance.get(`/api/v1/metrics/totalActivitiesAndPoints`)

      setDataTwo(response.data)
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  useEffect(() => {
    // getData()
  }, [])

  return (
    <Layout>
      <div style={{ padding: '20px' }}>
        <h2>Metrics</h2>
        {loading && <Spin />}
        <hr />
        <Card
          style={{ cursor: 'pointer', maxWidth: 300 }}
          onClick={() => {
            navigate(`/metrics/summarypage`)
          }}
        >
          <p>Summary page</p>
        </Card>

        <hr />
        <Button type="primary" style={{ cursor: 'pointer' }} onClick={getDataTwo} loading={loading}>
          Get total activities & their points (All time)
        </Button>
        {dataTwo && (
          <p>
            Total completed activites {dataTwo?.totalCompletedEvents} for {dataTwo?.totalPoints} points.
          </p>
        )}
        <hr />
        {loading ? (
          <div>
            <p>Please wait this may need sometime, depends on size of bucket and google response.</p>
          </div>
        ) : (
          <>
            <p style={{ fontSize: '1rem' }}>Result {data?.sizeString}</p>
            <Button type="primary" style={{ cursor: 'pointer' }} onClick={getData}>
              Get bucket size
            </Button>
            <p style={{ color: 'red' }}>
              Increased bucket size will cost more money each month, but it also depends on various things such as number of operations at bucket, trash of bucket etc. Please
              contact admin for proper billing each month.
            </p>
          </>
        )}
        <hr />
      </div>
    </Layout>
  )
}

export default Metrics
