import React, { useState, useRef, useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Modal, Avatar, Space, Input, Button, Select, Upload, message, Typography, Table, Popconfirm, Spin, InputNumber, DatePicker } from 'antd'
import { ArrowLeftOutlined, UploadOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'

import Layout from '../components/layout/Layout'
import axiosInstance from '../utils/axiosInstance'

import axios from 'axios'
const { Text } = Typography

function ClubEvents() {
  const navigate = useNavigate()
  const auth = useSelector((state) => state.auth)

  const [loading, setLoading] = useState(false)
  const [organizations, setOrganizations] = useState([])
  const [focusAreas, setOrgFocusAreas] = useState([])
  const [selectedOrg, setSelectedOrg] = useState('')
  const [createFocusAreaModal, setCreateFocusAreaModal] = useState(false)
  const [title, setTitle] = useState('')
  const [fullForm, setFullForm] = useState('')
  const [form, setForm] = useState({
    title: '',
    link: '',
    description: '',
    points: 0,
    focusArea: '',
    dateTime: '',
  })

  const onOk = (value) => {
    onChange({ dateTime: new Date(value) }) // store it with the form
  }

  // Text input field
  const onChange = (obj) => {
    // console.log(obj)
    setForm((prev) => ({ ...prev, ...obj }))
  }

  const columns = [
    {
      title: 'Profile Picture',
      dataIndex: 'icon',
      key: 'icon',
      render: (icon) => <Avatar src={icon && icon?.bucketResponse?.mediaLink} />,
    },
    {
      title: 'Name',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Full Form',
      dataIndex: 'fullForm',
      key: 'fullForm',
    },
    {
      title: 'Disabled',
      dataIndex: 'disabled',
      key: 'disabled',
      render: (dis) => (dis ? 'TRUE' : 'FALSE'),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => new Date(createdAt).toLocaleString(),
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      render: (_, record) => (
        <Space size="middle">
          <span style={{ color: '#1677ff', cursor: 'pointer' }} onClick={() => navigate(`/focusArea/${record._id}`)}>
            Open
          </span>
          {/* <Popconfirm
            title="Are you sure to disable this?"
            description="All the sub categories will be deleted under this."
            onConfirm={() => {
              onDelete(record._id)
            }}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <span style={{ color: 'red', cursor: 'pointer' }}>Disable</span>
          </Popconfirm> */}
        </Space>
      ),
    },
  ]

  const onDelete = async (focusId) => {
    const response = await axiosInstance.delete(`/api/v1/focus/deleteFocusArea?focusId=${focusId}`)
    alert('Success')
    window.location.reload()
  }

  const handleChange = (value) => {
    //
    setSelectedOrg(value)
    getFocusAreas(value)
  }

  const showModal = () => {
    if (!selectedOrg) {
      alert('Please select one first')
      return
    }
    setCreateFocusAreaModal(true)
  }

  const getMyInfo = async () => {
    try {
      setLoading(true)

      const response = await axiosInstance.get(`/api/v1/admin/me`)

      // Check the admin organizations
      if (response?.data?.user?.organizations && response?.data?.user?.organizations.length > 0) {
        const org = response.data.user.organizations
        // console.log(org)

        // store to render at JSX
        org.forEach((element, idx) => {
          if (idx == 0) handleChange(element._id) // auto selecting the first organization to fetch some results
          setOrganizations((prevElements) => [
            ...prevElements,
            {
              value: element._id,
              label: element.name,
            },
          ])
        })
      }

      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const getFocusAreas = async (v) => {
    try {
      setLoading(true)

      const response = await axiosInstance.get(`/api/v1/focus/getFocusAreas?organizationId=${v || selectedOrg}`)

      console.log(response.data.focusAreas)
      setOrgFocusAreas(response.data.focusAreas)

      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const createEvent = async () => {
    try {
      //   console.log(form)
      // Check if any field in the form is empty
      const isEmptyField = Object.entries(form).some(([key, value]) => {
        // Check if it's a string and empty after trimming
        if (typeof value === 'string') {
          return value.trim() === ''
        }
        // Check if it's a number and equals 0 or undefined
        if (typeof value === 'number') {
          return value === 0
        }
        // For any other types (optional, depending on your form structure)
        return !value
      })

      if (isEmptyField || !selectedOrg) {
        message.error('Please fill in all the fields.')
        return
      }

      const response = await axiosInstance.post(`/api/v1/clubEvents/createClubEvent`, {
        ...form,
        organization: selectedOrg,
      })
      // console.log('Event created successfully', response.data)
      message.success('Event created successfully.')

      setTimeout(() => {
        window.location.reload()
      }, 1000)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  useEffect(() => {
    getMyInfo()
  }, [])

  return (
    <Layout>
      <div>Club Events</div>
      <Modal
        title="Create an event"
        open={createFocusAreaModal}
        footer={null}
        onCancel={() => {
          setCreateFocusAreaModal(false)
        }}
      >
        <Input placeholder="Enter the title" style={{ marginBottom: 10 }} onChange={(e) => onChange({ title: e.target.value })} />
        <Input placeholder="Link" onChange={(e) => onChange({ link: e.target.value })} />
        <Input placeholder="Description" onChange={(e) => onChange({ description: e.target.value })} style={{ marginTop: 10, width: '100%' }} />
        <InputNumber
          placeholder="Points (Max:10000,  Min:1)"
          min={1}
          max={10000}
          onChange={(e) => onChange({ points: e })} // sending integer
          style={{ marginTop: 10, width: '100%' }}
        />
        <Select
          placeholder="Choose focus area"
          style={{
            width: `100%`,
            marginTop: 10,
          }}
          onChange={(value) => onChange({ focusArea: value })}
          options={focusAreas.map((el) => ({ label: el.fullForm, value: el._id }))}
        />
        <DatePicker
          showTime
          use12Hours
          showNow={false}
          style={{ marginTop: 10 }}
          onChange={(value, dateString) => {
            // console.log('Selected Time: ', value)
            // console.log('Formatted Selected Time: ', dateString)
          }}
          onOk={onOk}
        />
        {/* // todo: select Club on subit */}
        {/* // todo: select community event by default (Maybe on accpet, when mixing with 'events' collection) not sure about. */}
        <br />
        <br />
        <Button type="primary" onClick={createEvent} loading={loading} style={{ marginTop: 10 }}>
          Save
        </Button>
      </Modal>
      {organizations.length > 0 && (
        <div>
          <p>Choose organization for which the Event is for</p>
          <Select
            placeholder="Please select club or business"
            style={{
              width: 250,
              marginRight: 10,
            }}
            defaultValue={organizations[0].value}
            onChange={handleChange}
            options={organizations}
          />
          <Button type="primary" onClick={showModal}>
            Create Event
          </Button>
        </div>
      )}
    </Layout>
  )
}

export default ClubEvents
